import { Value } from '@sinclair/typebox/value'
import { type ApiKey, type UpdateApiKeyPayload, selectApiKeySchema } from 'tela-api/src/database/schemas/api-key'

export function useApiKey() {
    const { api } = useAPI()
    const apiKeys = useState<ApiKey[] | null>('api-keys', () => null)
    const notification = useToast()

    const isFetchingApiKeys = ref(false)

    async function fetchApiKeys() {
        const { currentWorkspace } = useWorkspaces()

        if (!currentWorkspace.value)
            return

        isFetchingApiKeys.value = true
        const apiKeyList = await api()['api-key'].get({
            $query: {
                workspaceId: currentWorkspace.value?.id,
            },
        })

        if (apiKeyList.error || !Array.isArray(apiKeyList.data)) {
            notification.add({
                title: 'Failed to fetch API keys',
                description: apiKeyList.error?.message,
                color: 'red',
            })
            return
        }

        isFetchingApiKeys.value = false
        apiKeys.value = apiKeyList.data.map(key => ({ ...key, expiresAt: new Date(key.expiresAt) }))
        return apiKeys.value
    }

    async function createApiKey(name: string) {
        const { currentWorkspace } = useWorkspaces()

        if (!currentWorkspace.value)
            return

        const response = await api()['api-key'].post({
            name,
            workspaceId: currentWorkspace.value.id,
        })

        if (response.error) {
            notification.add({
                title: 'Failed to create API key',
                description: response.error?.message,
                color: 'red',
            })
        }

        const newApiKey = toApiKey(response.data!)

        apiKeys.value = [...(apiKeys.value ?? []), newApiKey]

        return response.data
    }

    async function updateApiKey(key: string, update: UpdateApiKeyPayload) {
        const response = await api()['api-key'][key].patch(update)

        if (response.error || 'message' in response.data) {
            notification.add({
                title: 'Failed to update API key',
                description: response.error?.message,
                color: 'red',
            })
            return
        }

        const apiKey = response.data
        if (apiKeys.value) {
            apiKeys.value = apiKeys.value.map((key) => {
                if (key.id === apiKey.id)
                    return { ...apiKey, expiresAt: new Date(apiKey.expiresAt) }
                else
                    return key
            })
        }
        else {
            apiKeys.value = []
        }

        return apiKey
    }

    async function deleteApiKey(keyId: string) {
        const response = await api()['api-key'][keyId].delete()

        if (response.error) {
            notification.add({
                title: 'Failed to delete API key',
                description: response.error?.message,
                color: 'red',
            })
            return
        }

        if (apiKeys.value) {
            apiKeys.value = apiKeys.value.filter(k => k.id !== keyId)
        }
    }

    return { apiKeys, fetchApiKeys, createApiKey, updateApiKey, isFetchingApiKeys, deleteApiKey }
}

function toApiKey(apiKey: any) {
    return Value.Convert(selectApiKeySchema, apiKey) as ApiKey
}
